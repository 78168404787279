<template>
    <focus-trap :active="trapActive">
        <dialog class="slm-image-fullscreen" :class="{'slm-image-fullscreen__image-carousel': (title || description)}">
            <span v-html="fullScreenImage && fullScreenImage.full ? fullScreenImage.full : ''"></span>
            <div v-if="loading" class="slm-loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div v-if="(title || description) && !loading"  class="slm-image-fullscreen__content">
                <h2 v-if="title" class="slm-link-carousel__post--title">{{title}}</h2>
                <p v-if="description" class="slm-link-carousel__post--description">{{description}}</p>
            </div>
            <button class="slm-image-fullscreen__close" aria-expanded="true" @click="hideDialog">
                <span class="show-for-sr">Stäng</span>
            </button>
        </dialog>
    </focus-trap>
</template>

<script>
import { FocusTrap } from 'focus-trap-vue';
export default {
    name: 'full-screen',
    props: ['image'],
    data() {
        return {
            trapActive: true,
        }
    },
    computed: {
        fullScreenImage() {
            return this.$store.state.fullScreenImage;
        },
        loading() {
            return this.$store.state.loading;
        },
        title() {
            return this.$store.state.fullScreenImageTitle;
        },
        description() {
            return this.$store.state.fullScreenImageDescription;
        }
    },
    methods: {
        hideDialog() {
            this.trapActive = false;
            this.$store.commit('setDialogOpen', false);
            this.$store.commit('setFullScreenImage', null);
            this.$store.commit('setFullScreenImageId', null);
            this.$store.commit('setFullSCreenImageContent', {title: "", description: ""});
        },
        escapeClick(e) {
            if(e.key === 'Escape') {
                this.hideDialog()
            }
        }
    },
    components: {
        FocusTrap,
    },
    mounted() {
        this.$store.dispatch('getFullScreenImage');
        document.addEventListener('keyup', this.escapeClick);
    }
}
</script>