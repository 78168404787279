<template>
    <div class="slm-link-carousel__post">
        <div class="slm-link-carousel__post--image-wrapper">
            <span v-html="image.image"></span>
            <button class="slm-image-fullscreen__button" @click="showFullScreen">
                <span class="show-for-sr">Visa bild i helskärm</span>
            </button>
        </div>
        <h2 v-if="image.title" class="slm-link-carousel__post--title">{{image.title}}</h2>
        <p v-if="image.description" class="slm-link-carousel__post--description">{{image.description}}</p>
    </div>
</template>

<script>
export default {
    name: 'post-component',
    props: ['image'],
    computed: {},
    methods: {
        showFullScreen() {
            this.$store.commit('setFullScreenImageContent', {title: this.image.title, description: this.image.description});
            this.$store.commit('setFullScreenImageId', this.image.ID);
            this.$store.commit('setDialogOpen', true);
        }
    }
}
</script>