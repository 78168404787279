const setStateMutation = (state, payload) => {
    Object.assign(state, payload);
}

const setDialogOpenMutation = (state, payload) => {
    state.dialogOpen = payload;
}

const setLoadingMutation = (state, payload) => {
    state.loading = payload;
}

const setFullScreenImageMutation = (state, payload) => {
    state.fullScreenImage = payload;
}

const setFullScreenImageIdMutation = (state, payload) => {
    state.fullScreenImageId = payload;
}

const setFullScreenImageContentMutation = (state, payload) => {
    state.fullScreenImageTitle = payload.title;
    state.fullScreenImageDescription = payload.description;
}

const mutations = {
    setState: setStateMutation,
    setDialogOpen: setDialogOpenMutation,
    setLoading: setLoadingMutation,
    setFullScreenImage: setFullScreenImageMutation,
    setFullScreenImageId: setFullScreenImageIdMutation,
    setFullScreenImageContent: setFullScreenImageContentMutation
}

export default mutations;
