const state = {
    images: [],
    loop: false,
    dialogOpen: false,
    loading: true,
    fullScreenImageId: null,
    fullScreenImage: null,
    fullScreenImageTitle: "",
    fullScreenImageDescription: ""
}

export default state;