const initAppAction = ({ commit }, payload ) => {
    commit('setState', payload)
}

const getFullScreenImageAction = ({ commit, state }) => {
    commit('setLoading', true);
    const id = state.fullScreenImageId;
    fetch('/wp-json/slm/v1/attachment/' + id)
        .then(response => response.json())
        .then(image => {
            commit('setFullScreenImage', image);
            commit('setLoading', false);
        });
}

const actions = {
    initApp: initAppAction,
    getFullScreenImage: getFullScreenImageAction,
};

export default actions;